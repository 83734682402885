import { DateTimeModel } from "../DateTimeModel";

export class HolidayRequestUserModel {
  id: number;
  startDateComponents: DateTimeModel;
  endDateComponents: DateTimeModel;
  holidayRequestTypeId?: number;
  specialHolidayRequestTypeId?: number;
  holidayRequestTypeName: string;

  startDate: string;
  endDate: string;

  constructor() {
    this.holidayRequestTypeId = null;
    this.specialHolidayRequestTypeId = null;
  }
}
