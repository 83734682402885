import { Component, OnInit, Input } from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";
import { finalize, takeUntil } from "rxjs/operators";
import { ProjectModel } from "src/app/models/projectModels/ProjectModel";
import { ProjectService } from "src/app/services/project.service";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { DialogService } from "src/app/shared/services/dialog.service";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { clone } from "src/app/shared/utils/Utils";

@Component({
  selector: "trackify-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.scss"],
})
export class ProjectComponent extends GridComponentBase<ProjectModel> implements OnInit {
  public displayArchived: Boolean = false;
  public archivedProjects: ProjectModel[] = [];

  @Input() isInTab: boolean = false
  @Input() clientId;

  constructor(
    private dialogService: DialogService,
    private projectService: ProjectService,
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        field: "coloredCell",
        headerName: "Color",
        width: 100,
        suppressSizeToFit: true,
        cellStyle: function (params) {
          return { "background-color": params.data.color };
        },
      },
      {
        field: "name",
        headerName: "Name",
        width: 400,
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) => (x.data.clientName ? x.data.clientName : "N/A"),
        headerName: "Client",
        width: 400,
        suppressSizeToFit: false,
      },
      {
        field: "isArchived",
        headerName: "Archived",
        width: 150,
        hide: true,
        suppressSizeToFit: false,
      },
      {
        headerName: "Archive",
        headerClass: "text-center",
        type: ["buttonColumn"],
        pinned: 'right',
        cellRendererParams: {
          buttonClass: "btn btn-primary btn-xs",
          buttonText: "",
          iconClass: "fas fa-ban white-color",
          shouldCheckArchiveStatus: true,
          onClick: this._archiveProject.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      },
      //Hide Monthy Report button from now
      // {
      //   headerName: "Monthly Report",
      //   headerClass: "text-center",
      //   type: ["buttonColumn"],
      //   pinned: 'right',
      //   cellRendererParams: {
      //     buttonClass: "btn btn-success btn-xs",
      //     buttonText: "",
      //     iconClass: "fas fa-chart-line",
      //     onClick: this._exportMonthlyReport.bind(this),
      //   },ng serve
      //   cellRenderer: "buttonCellRenderer",
      // }
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No Projects</span>';
  }
  ngOnInit() {
    this.startLoader();
    if (this.clientId) {
      this.projectService.getAllUnarchivedProjectsForClient(this.clientId)
        .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
        .subscribe(projects => {
          this.data = projects;
        });
    }
    else {
      this.projectService.getAllUnarchivedProjects()
        .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
        .subscribe(projects => {
          this.data = projects;
        });
    }
  }

  onRowSelected(_$event: any): void {
    if (!_$event.node.isSelected()) return;
    this.currentSelection = clone(_$event.data);
    this.currentSelectionIdx = this.data.indexOf(this.data.find(project => project.id == this.currentSelection.id));
  }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

  public showHideArchive() {
    this.startLoader();
    this.displayArchived = !this.displayArchived;
    if (this.displayArchived) {
      this.gridOptions.columnApi.setColumnVisible("isArchived", true);
      if (this.clientId) {
        this.projectService.getAllProjectsForClient(this.clientId)
          .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
          .subscribe(projects => {
            this.data = projects;
          });
      }
      else {
        this.projectService.getAllProjects()
          .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
          .subscribe(projects => {
            this.data = projects;
          });
      }
    } else {
      this.gridOptions.columnApi.setColumnVisible("isArchived", false);
      if (this.clientId) {
        this.projectService.getAllUnarchivedProjectsForClient(this.clientId)
          .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
          .subscribe(projects => {
            this.data = projects;
          });
      }
      else {
        this.projectService.getAllUnarchivedProjects()
          .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
          .subscribe(projects => {
            this.data = projects;
          });
      }
      this.updateGridAfterDelete();
    }
    this.gridOptions.api.sizeColumnsToFit();
  }

  // private _exportMonthlyReport(): void {
  //   console.log("This method will export your monthly report");
  // }

  private _archiveProject(obj: ICellRendererParams) {

    if (obj.data.isArchived) {
      this.dialogService.showSimpleDialog('Error!', "This project is already archived", 'error');
      return;
    }

    this.dialogService.showDialog({
      title: `Are you sure you want to archive this project?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DC3545',
      confirmButtonText: 'Archive'
    }).then(result => {
      if (!!result.value) {
        this.gridOptions.api.showLoadingOverlay()
        this.projectService.archiveProject(this.currentSelection.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.data.splice(this.currentSelectionIdx, 1);
            if (!this.displayArchived) {
              this.updateGridAfterDelete();
            } else {
              obj.data.isArchived = !obj.data.isArchived;
              this.gridOptions.api.refreshCells();
              this.updateGrid(this.data);
            }

            this.currentSelection = null;
            this.dialogService.showSuccessMessage('Success!', 'Selected project archived successfully.');
          });
      }
    });
  }
}
