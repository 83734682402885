import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { GridComponentBase } from 'src/app/shared/components/base/GridComponentBase';
import { AgGridUtils } from 'src/app/shared/utils/AgGridUtils';
import { clone, getCurrencyName, getValue, getYesOrNo } from 'src/app/shared/utils/Utils';
import { ClientConstants } from 'src/app/constants/client-constants';

import { ClientsService } from 'src/app/services/clients.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

import { ClientDetailsModel } from 'src/app/models/clients/ClientDetailsModel';

@Component({
  selector: 'trackify-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent extends GridComponentBase<ClientDetailsModel> implements OnInit {

  @ViewChild("clientModal", { static: true }) modal: ModalDirective;

  client: ClientDetailsModel;
  currencies = ClientConstants.Currencies;

  constructor(
    public router: Router,
    private dialogService: DialogService,
    private clientsService: ClientsService
  ) {
    super();

    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        field: 'name',
        headerName: 'Name',
        width: 160, sort: 'asc',
        valueFormatter: params => getValue(params.data.name),
        suppressSizeToFit: true
      },
      {
        field: 'microsoftName',
        headerName: 'Microsoft Name',
        valueFormatter: params => getValue(params.data.microsoftName),
        width: 160,
        suppressSizeToFit: true
      },
      {
        field: 'smartBillName',
        headerName: 'SmartBill Name',
        valueFormatter: params => getValue(params.data.smartBillName),
        width: 160,
        suppressSizeToFit: true
      },
      {
        field: 'smartBillCif',
        headerName: 'SmartBill CIF',
        valueFormatter: params => getValue(params.data.smartBillCif),
        width: 140,
        suppressSizeToFit: true
      },
      {
        field: 'currency',
        headerName: 'Currency',
        valueFormatter: params => getCurrencyName(params.data.currency),
        width: 115,
        suppressSizeToFit: true
      },
      {
        field: 'tva',
        headerName: 'TVA',
        valueFormatter: params => getValue(params.data.tva),
        width: 85,
        suppressSizeToFit: true
      },
      {
        field: 'isBimonthlyBilled',
        headerName: 'Bimonthly Billed',
        valueFormatter: params => getYesOrNo(params.data.isBimonthlyBilled),
        width: 120,
        suppressSizeToFit: true
      },
      {
        field: 'generateAzureProforma',
        headerName: 'Generate Azure Proforma',
        valueFormatter: params => getYesOrNo(params.data.generateAzureProforma),
        width: 150,
        suppressSizeToFit: true
      },
      {
        field: 'generateOfficeProforma',
        headerName: 'Generate Office Proforma',
        valueFormatter: params => getYesOrNo(params.data.generateOfficeProforma),
        width: 150,
        suppressSizeToFit: true
      },
      {
        field: 'generateTimesheetProforma',
        headerName: 'Generate Timesheet Proforma',
        valueFormatter: params => getYesOrNo(params.data.generateTimesheetProforma),
        minWidth: 180,
        suppressSizeToFit: false
      },
      {
        headerName: 'Edit',
        pinned: 'right',
        headerClass: 'text-center',
        type: ['buttonColumn'],
        cellRendererParams: {
          buttonClass: 'btn btn-primary btn-xs',
          buttonText: '',
          iconClass: 'fas fa-edit white-color',
          onClick: this._onEdit.bind(this)
        },
      },
      {
        headerName: 'Archive',
        pinned: 'right',
        headerClass: 'text-center',
        type: ['buttonColumn'],
        cellRendererParams: {
          buttonClass: 'btn btn-primary btn-xs',
          buttonText: '',
          iconClass: 'fas fa-ban white-color',
          onClick: this._onArchive.bind(this)
        }
      }
    ]);

    this.gridOptions.overlayNoRowsTemplate = '<span class="ag-overlay-no-rows-center">No client entries.</span>';
  }

  ngOnInit(): void {
    this.startLoader();

    this.clientsService
      .getClients()
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
      .subscribe(clients => {
        this.data = clients;
      });
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;
    this.currentSelection = clone($event.data);
    this.client = clone(this.currentSelection);
    this.currentSelectionIdx = this.data.indexOf(this.data.find(u => u.id == this.currentSelection.id));
  }

  onRowDoubleClicked(_: any): void {
    this._onEdit();
  }

  validateModel(_: string): string { return ''; }

  isModelValid(): boolean { return true; }

  onAdd() {
    this.client = new ClientDetailsModel();
    this.modal.show();
  }

  saveChanges() {
    if (this.client.id) {
      this._editClient();
    }
    else {
      this._addClient();
    }
  }

  private _onEdit() {
    this.client = clone(this.currentSelection);
    this.modal.show();
  }

  private _onArchive() {
    this.dialogService.showDialog({
      title: `Are you sure you want to archive this client?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DC3545',
      confirmButtonText: 'Archive'
    }).then((result) => {
      if (!result.value) return;
      this._archiveClient();
    });
  }

  private _addClient() {
    this.clientsService
      .addClient(this.client)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(updatedClient => {
        this.updateGridAfterAdd(updatedClient);
        this.modal.hide();
        this.dialogService.showSuccessMessage('Success!', 'The client was added successfully.');
      }, err => { this.dialogService.showSimpleDialog("Error", err, "error"); });
  }

  private _editClient() {
    this.clientsService
      .editClient(this.client)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(updatedClient => {
        this.updateGridAfterEdit(updatedClient);
        this.currentSelection = this.client;
        this.modal.hide();
        this.dialogService.showSuccessMessage('Success!', 'The client was updated successfully.');
      }, err => { this.dialogService.showSimpleDialog("Error", err, "error"); });
  }

  private _archiveClient() {
    this.clientsService
      .archiveClient(this.currentSelection.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(_ => {
        this.data.splice(this.currentSelectionIdx, 1);
        this.updateGridAfterDelete();
        this.currentSelection = null;
        this.client = null;
        this.dialogService.showSuccessMessage('Success!', 'The client was archived successfully.');
      }, err => { this.dialogService.showSimpleDialog("Error", err, "error"); });
  }
}