import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { finalize, takeUntil } from 'rxjs/operators';
import { HolidayStatsConstants } from 'src/app/constants/holiday-stats-constants';
import { HolidayStatsModel } from 'src/app/models/holiday-stats/HolidayStatsModel';
import { HolidayStatsService } from 'src/app/services/holiday-request/holiday-stats.service';
import { GridComponentBase } from 'src/app/shared/components/base/GridComponentBase';
import { AgGridUtils } from 'src/app/shared/utils/AgGridUtils';
import { formatDate } from 'src/app/shared/utils/Formatters';
import { clone } from 'src/app/shared/utils/Utils';


@Component({
  selector: 'trackify-holiday-stats',
  templateUrl: './holiday-stats.component.html',
  styleUrls: ['./holiday-stats.component.scss']
})
export class HolidayStatsComponent extends GridComponentBase<HolidayStatsModel> implements OnInit {

  currentDate: string;
  formatDateForTable = formatDate;

  constructor(
    private router: Router,
    private holidayStatsService: HolidayStatsService
  ) {
    super();
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();

    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        field: "userName",
        headerName: "User",
        width: 180,
        sort: "asc",
        suppressSizeToFit: false,
      },
      {
        field: "currentMonthHolidays",
        headerName: "This Month",
        width: 180,
        suppressSizeToFit: false,
        cellStyle: function (params) {
          if (params.data.pastSixMonthsHolidays + params.data.currentMonthHolidays > HolidayStatsConstants.GREEN_SIGNAL_LIMIT) {
            return _colorCellGreen();
          } else {
            if (params.data.nextThreeMonthsHolidays + params.data.currentMonthHolidays + params.data.pastSixMonthsHolidays < HolidayStatsConstants.GREEN_SIGNAL_LIMIT) {
              return _colorCellRed();
            }
          }
        }
      },
      {
        field: "nextThreeMonthsHolidays",
        headerName: "Next 3 Months",
        width: 180,
        suppressSizeToFit: false,
        cellStyle: function (params) {
          if (params.data.currentMonthHolidays + params.data.nextThreeMonthsHolidays + params.data.pastThreeMonthsHolidays > HolidayStatsConstants.GREEN_SIGNAL_LIMIT) {
            return _colorCellGreen();
          } else {
            return _colorCellRed();
          }
        }
      },
      {
        field: "pastThreeMonthsHolidays",
        headerName: "Past 3 Months",
        width: 180,
        suppressSizeToFit: false,
        cellStyle: function (params) {
          if (params.data.pastThreeMonthsHolidays < HolidayStatsConstants.WARNING_THREE_MONTHS_LIMIT) {
            return _colorCellYellow();
          }
        }
      },
      {
        field: "pastSixMonthsHolidays",
        headerName: "Past 6 Months",
        width: 180,
        suppressSizeToFit: false,
        cellStyle: function (params) {
          if (params.data.pastSixMonthsHolidays < HolidayStatsConstants.GREEN_SIGNAL_LIMIT) {
            return _colorCellRed();
          }
        }
      },
      {
        field: "pastTwelveMonthsHolidays",
        headerName: "Past 12 Months",
        width: 180,
        suppressSizeToFit: false,
        cellStyle: function (params) {
          let holidaysNumber = params.data.holidaysBase;

          if(currentDate > params.data.employmentDate) {
            holidaysNumber += (currentYear - params.data.employmentDate.getFullYear());
          }

          if (params.data.pastTwelveMonthsHolidays < (80 * holidaysNumber) / 100) {
            return _colorCellRed();
          }
        }
      },
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No Holiday Stats</span>';
  }

  ngOnInit() {
    this.currentDate = moment().toISOString();
    this.startLoader();
    this._getHolidayStats();
  }

  private _getHolidayStats() {
    this.holidayStatsService.getBasicStatistics()
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe(stats => {
        this.data = stats
      });
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;
    this.currentSelection = clone($event.data);
    this.currentSelectionIdx = this.data.indexOf(this.data.find(u => u.userId == this.currentSelection.userId));
  }

  onRowDoubleClicked(_: any): void {
    this._openChartsPage(this.currentSelection);
  }

  private _openChartsPage(stats: HolidayStatsModel) {
    if (!stats) return;

    this.router.navigateByUrl("holiday-charts/" + stats.userId);
  }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }

}

function _colorCellRed(): {} {
  return { backgroundColor: HolidayStatsConstants.RED_WARNING_COLOR, color: "white" };
}

function _colorCellGreen() {
  return { backgroundColor: HolidayStatsConstants.GREEN_WARNING_COLOR, color: "white"};
}

function _colorCellYellow() {
  return { backgroundColor: HolidayStatsConstants.WARNING_THREE_MONTHS_LIMIT_COLOR, color: "white" };
}
