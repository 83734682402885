import { finalize, takeUntil } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";

import { DailyStatsConstants } from "src/app/constants/daily-stats-constats";

import { DailyStatsService } from "src/app/services/users-daily-stats.service";

import { DailyStatsModel } from "src/app/models/dailyStats/DailyStatsModel";
import { formatDate } from "../../shared/utils/Formatters";
import * as moment from "moment";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { ValueFormatterParams } from "ag-grid-community";

@Component({
  selector: "trackify-daily-stats",
  templateUrl: "./daily-stats.component.html",
  styleUrls: ["./daily-stats.component.scss"],
})
export class DailyStatsComponent extends GridComponentBase<DailyStatsModel>
  implements OnInit {
  currentDate: string;
  holiday: string = DailyStatsConstants.HOLIDAY;
  nationalHoliday: string = DailyStatsConstants.NATIONAL_HOLIDAY;
  formatDateForTable = formatDate;

  constructor(private dailyStatsService: DailyStatsService) {
    super();

    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        field: "userName",
        headerName: "User",
        width: 180,
        sort: "asc",
        suppressSizeToFit: false,
      },
      {
        field: "todaysStatus",
        headerName: "Todays Status",
        width: 160,
        suppressSizeToFit: false,
        cellStyle: (params) => this._setCellStyleForTodaysStatus(params)
      },
      {
        field: "targetHours",
        headerName: "Target Hours",
        width: 150,
        suppressSizeToFit: false,
      },
      {
        field: "workedHours",
        headerName: "Worked Hours",
        width: 141,
        suppressSizeToFit: true,
        valueFormatter: (params) => params.data.workedHours.toFixed(2),
      },
      {
        headerName: "Holidays Hours Taken",
        width: 240,
        suppressSizeToFit: false,
        valueFormatter: (params) => this._computeHolidaysHoursTaken(params)
          
      },
      {
        field: "nationalHolidays",
        headerName: "National Holidays",
        width: 170,
        suppressSizeToFit: true,
      },
      {
        headerName: "Payable Overtime",
        width: 150,
        suppressSizeToFit: false,
        valueFormatter: (params) => this._computePayableOvertime(params),
        cellStyle: (params) => this._setCellStyleForPayableOvertime(params)
      },
      {
        headerName: "Total Overtime",
        width: 150,
        suppressSizeToFit: false,
        valueFormatter: (params) => params.data.overtime.toFixed(2),
        cellStyle: (params) => this._setCellStyleForTotalOvertime(params)
      }
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No Daily Stats entries.</span>';
  }

  ngOnInit() {
    this.currentDate = moment().toISOString();
    this.startLoader();
    this._getDailyStats();
  }

  private _getDailyStats(): void {
    this.dailyStatsService
      .getAllDailyStats()
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe(
        (dailyStats: DailyStatsModel[]) => {
          this.data = dailyStats;
      });
  }

  private _computeHolidaysHoursTaken(params: ValueFormatterParams): string {
    const holidaysHoursTaken = params.data.paidTypeHolidays + params.data.notPaidTypeHolidays + params.data.medicalTypeHolidays 
    + params.data.specialTypePaidHolidays + params.data.specialTypeNotPaidHolidays;

    if(holidaysHoursTaken == 0)
      return "0";

    let holidaysHoursDetails = `${holidaysHoursTaken} (`;

    if(params.data.paidTypeHolidays > 0){
      holidaysHoursDetails += `${params.data.paidTypeHolidays}h paid, `;
    }

    if(params.data.notPaidTypeHolidays > 0){
      holidaysHoursDetails += `${params.data.notPaidTypeHolidays}h not paid, `;
    }

    if(params.data.medicalTypeHolidays > 0){
      holidaysHoursDetails += `${params.data.medicalTypeHolidays}h medical, `;
    }

    if(params.data.specialTypePaidHolidays > 0){
      holidaysHoursDetails += `${params.data.specialTypePaidHolidays}h paid special, `;
    }

    if(params.data.specialTypeNotPaidHolidays > 0){
      holidaysHoursDetails += `${params.data.specialTypePaidHolidays}h not paid special, `;
    }
    
    return holidaysHoursDetails.slice(0,-2) + ")";
  }

  private _computePayableOvertime(params: ValueFormatterParams): string {
    const payableOvertime = (params.data.paidTypeHolidays + params.data.workedHours + params.data.medicalTypeHolidays + params.data.specialTypePaidHolidays - params.data.targetHours);

    if (payableOvertime > 0 ){
      return payableOvertime.toFixed(2);
    }

    return "0";
  }

  private _setCellStyleForPayableOvertime(params: ValueFormatterParams): { color: string; fontWeight: string; } {
    if (params.data.paidTypeHolidays + params.data.workedHours + params.data.medicalTypeHolidays + params.data.specialTypePaidHolidays > params.data.targetHours) {
      return { color: "green", fontWeight: "bold" };
    }

    return { color: "black", fontWeight: "normal" };
  }

  private _setCellStyleForTotalOvertime(params: ValueFormatterParams): { color: string; fontWeight: string; } {
    if (params.data.overtime > 0) {
      return { color: "green", fontWeight: "bold" };
    }
    else if (params.data.overtime < 0) {
      return { color: "red", fontWeight: "bold" }
    }

    return { color: "black", fontWeight: "normal"};
  }

  private _setCellStyleForTodaysStatus(params: ValueFormatterParams): { backgroundColor: string; } {
    if ( params.value == DailyStatsConstants.HOLIDAY || params.value == DailyStatsConstants.NATIONAL_HOLIDAY) {
      return { backgroundColor: "lightsalmon" };
    }

    return { backgroundColor: "lightgreen" };
  }

  onRowSelected(): void { }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }
}
