import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { finalize, takeUntil } from "rxjs/operators";

import { DialogService } from "src/app/shared/services/dialog.service";
import { LoadableComponentBase } from "src/app/shared/utils/LoadableComponentBase";

import { UsefulLinkService } from "src/app/services/usefulLink.service";

import { UsefulLinkModel } from "src/app/models/usefulLinks/UsefulLinkModel";

@Component({
  selector: "trackify-usefull-links",
  templateUrl: "./usefull-links.component.html",
  styleUrls: ["./usefull-links.component.scss"],
})
export class UsefullLinksComponent extends LoadableComponentBase implements OnInit {
  @Input() role: string;

  links: UsefulLinkModel[] = [];
  link: UsefulLinkModel;
  isAdminOrHr: boolean;
  urlValidationPattern: RegExp = new RegExp(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
  );
  nameValidationPattern = "[A-Za-z0-9 ]{1,30}";
  isModalButtonEnabled = true;

  @ViewChild("usefulLinkModal", { static: true }) modal: ModalDirective;
  maxLinkNameLength = 30;

  constructor(
    private usefulLinkService: UsefulLinkService,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit() {
    this.startLoader();
    this._getUserUsefulLinks();
    this._verifyRole();
  }

  private _verifyRole() {
    this.role === "Regular" ? this.isAdminOrHr = false : this.isAdminOrHr = true;
  }

  private _getUserUsefulLinks() {
    this.usefulLinkService.getUserUsefulLinks()
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe(
        (result) => {
          this.links = result;
        });
  }

  saveLink() {
    this.isModalButtonEnabled = false;
    if (
      this.link.name.trim().length == 0 ||
      !this.link.url.match(this.urlValidationPattern) ||
      this.link.name.length > this.maxLinkNameLength
    ) {
      this.isModalButtonEnabled = true;
      return;
    }

    this.usefulLinkService.addUsefulLinks(this.link)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (_) => {
          this.links.push(this.link);
          this.link = null;
          this.modal.hide();
          this.dialogService.showSimpleDialog(
            "Succes!",
            "Link added successfully",
            "success"
          );
          this.isModalButtonEnabled = true;
        },
        (_) => {
          this.dialogService.showErrorMessage("Oops...", "Something Went wrong");
          this.link = null;
          this.modal.hide();
          this.isModalButtonEnabled = true;
        }
      );
  }

  public goToLink(link: UsefulLinkModel) {
    window.open(link.url, "_blank");
  }

  openModal() {
    this.link = new UsefulLinkModel();
    this.modal.show();
  }

  isUrlValid() {
    if (!this.link.url) return true;
    return this.link.url.length > 0 && this.link.url.match(this.urlValidationPattern);
  }
}
