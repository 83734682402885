import { Component, Input, OnInit } from '@angular/core';
import { BenefitRequest } from 'src/app/models/admin-benefits/BenefitRequest';
import { GridComponentBase } from 'src/app/shared/components/base/GridComponentBase';
import { AgGridUtils } from 'src/app/shared/utils/AgGridUtils';
import { clone } from 'src/app/shared/utils/Utils';
import { BenefitRequestService } from 'src/app/services/benefits/benefitRequest.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { formatDate } from 'src/app/shared/utils/Formatters';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ChangeStringFormat } from "src/app/shared/utils/Formatters";

@Component({
  selector: 'trackify-admin-benefits-cancelling-request',
  templateUrl: './admin-benefits-cancelling-request.component.html',
  styleUrls: ['./admin-benefits-cancelling-request.component.scss']
})
export class AdminBenefitsCancellingRequestComponent extends GridComponentBase<BenefitRequest> implements OnInit {

  @Input() isInTab: boolean = false;
  benefitRequest: BenefitRequest = null;
  formatDateForTable = formatDate;
  cancellingRequests: BenefitRequest[] = [];

  constructor(
    private benefitRequestService: BenefitRequestService,
    private dialogService: DialogService
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        valueGetter: (x) => (x.data.owner.firstName + " " + x.data.owner.lastName),
        headerName: "User",
        width: 150,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.benefit.points),
        headerName: "Points",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.benefit.benefitCategory.name),
        headerName: "Category",
        width: 150,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (ChangeStringFormat(x.data.benefit.benefitType.name)),
        headerName: "Benefit Type",
        width: 150,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.benefit.name),
        headerName: "Benefit Name",
        width: 150,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (this.formatDateForTable(x.data.dateAdded)),
        headerName: "Submission Date",
        width: 170,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.details ? x.data.details : "N/A"),
        headerName: "Details",
        minWidth: 300,
        suppressSizeToFit: false,
      },
      {
        headerName: "Accept",
        headerClass: "text-center",
        type: ["buttonColumn"],
        pinned: 'right',
        cellRendererParams: {
          buttonClass: "btn btn-success",
          buttonText: "",
          iconClass: "fas fa-check",
          onClick: this._onAccept.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      },
      {
        headerName: "Decline",
        headerClass: "text-center",
        type: ["buttonColumn"],
        pinned: 'right',
        cellRendererParams: {
          buttonClass: "btn btn-danger",
          buttonText: "",
          iconClass: "fas fa-times",
          onClick: this._onDecline.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      }
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No benefit requests entries.</span>';
  }

  ngOnInit(): void {
    this.startLoader();
    this._loadData();
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;
    this.benefitRequest = clone($event.data);
    this.currentSelectionIdx = this.benefitRequest.id;
  }

  onRowDoubleClicked(_: any): void {
    return;
  }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    throw new Error('Method not implemented.');
  }

  private _onAccept(): void {
    this.startLoader();
    this.dialogService
      .showDialog({
        title: `Are you sure you want to accept this benefit request?`,
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#18ce0f",
        confirmButtonText: "Accept",
      })
      .then((result) => {
        if (!result.value) return;
        this.benefitRequestService
          .approveBenefitRequest(this.benefitRequest.id)
          .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
          .subscribe(() => {
            this.data.splice(this.currentSelectionIdx, 1);
            this.updateGridAfterDelete();
            this.benefitRequest = null;
            this.dialogService.showSuccessMessage(
              "Success!",
              "Selected benefit request has been accepted."
            )
          },
            (err) => {
              this.dialogService.showSimpleDialog("Error", err, "error");
            }
          );
      });
  }

  private _onDecline(): void {
    this.startLoader();
    this.dialogService
      .showDialog({
        title: `Are you sure you want to decline this benefit request?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DC3545",
        confirmButtonText: "Decline",
      })
      .then((result) => {
        if (!result.value) return;
        this.benefitRequestService
          .declineBenefitRequest(this.benefitRequest.id)
          .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
          .subscribe(() => {
            this.data.splice(this.currentSelectionIdx, 1);
            this.updateGridAfterDelete();
            this.benefitRequest = null;
            this.dialogService.showSuccessMessage(
              "Success!",
              "Selected benefit request has been declined."
            );
          },
            (err) => {
              this.dialogService.showSimpleDialog("Error", err, "error");
            }
          );
      });
  }

  private _loadData(): void {
    this.benefitRequestService.getDeleteBenefitRequests()
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe((benefitRequests) => {
        this.data = benefitRequests;
      });
  }
}