import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiConstants } from "../constants/api-constants";
import { ProjectModel } from "../models/projectModels/ProjectModel";
import { ApiService } from "../shared/services/api.service";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  private _apiProjectUrl = ApiConstants.PROJECTS_ENDPOINT;

  constructor(private apiService: ApiService) { }

  public getAllUnarchivedProjects(): Observable<ProjectModel[]> {
    return this.apiService.get<ProjectModel[]>(`${this._apiProjectUrl}`);
  }

  public getAllUnarchivedProjectsForClient(clientId: number): Observable<ProjectModel[]> {
    return this.apiService.get<ProjectModel[]>(`${this._apiProjectUrl}/${clientId}`);
  }

  public getAllProjects(): Observable<ProjectModel[]> {
    return this.apiService.get<ProjectModel[]>(`${this._apiProjectUrl}/archivedAndUnarchived`);
  }

  public getAllProjectsForClient(clientId: number): Observable<ProjectModel[]> {
    return this.apiService.get<ProjectModel[]>(`${this._apiProjectUrl}/archivedAndUnarchived/${clientId}`);
  }

  public archiveProject(projectId: number): Observable<ProjectModel> {
    return this.apiService.delete(`${this._apiProjectUrl}/${projectId}`);
  }
}
