import { Component, Input, OnInit } from '@angular/core';
import { BenefitRequest } from 'src/app/models/admin-benefits/BenefitRequest';
import { GridComponentBase } from 'src/app/shared/components/base/GridComponentBase';
import { AgGridUtils } from 'src/app/shared/utils/AgGridUtils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { clone } from 'src/app/shared/utils/Utils';
import { BenefitRequestService } from 'src/app/services/benefits/benefitRequest.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { formatDate } from 'src/app/shared/utils/Formatters';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BenefitTypeDto, BenefitTypeEnum } from 'src/app/models/admin-benefits/BenefitTypeDto';
import { ChangeStringFormat } from "src/app/shared/utils/Formatters";
import { EventDetailsModalComponent } from '../user-benefits/benefit-card/modals/event-details-modal/event-details-modal.component';

@Component({
  selector: 'trackify-admin-benefits-requests',
  templateUrl: './admin-benefits-requests.component.html',
  styleUrls: ['./admin-benefits-requests.component.scss']
})

export class AdminBenefitsRequestsComponent extends GridComponentBase<BenefitRequest> implements OnInit {

  @Input() isInTab: boolean = false;
  benefitRequest: BenefitRequest = null;
  formatDateForTable = formatDate;
  enrollingRequests: BenefitRequest[] = [];
  addValue: string = 'Add';
  eventModalRef: BsModalRef;

  constructor(
    private benefitRequestService: BenefitRequestService,
    private dialogService: DialogService,
    private bsModalService: BsModalService
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        valueGetter: (x) => (x.data.owner.firstName + " " + x.data.owner.lastName),
        headerName: "User",
        width: 250,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.benefit.points),
        headerName: "Points",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.benefit.benefitCategory.name),
        headerName: "Category",
        width: 170,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (ChangeStringFormat(x.data.benefit.benefitType.name)),
        headerName: "Benefit Type",
        width: 170,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.benefit.name),
        headerName: "Benefit Name",
        width: 170,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (this.formatDateForTable(x.data.dateAdded)),
        headerName: "Submission Date",
        width: 150,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (this.formatDateForTable(x.data.eventDate)),
        headerName: "Event Date",
        width: 150,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.details ? x.data.details : "N/A"),
        headerName: "Details",
        width: 300,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (x.data.benefitRequestType ? x.data.benefitRequestType : "N/A"),
        headerName: "Request Type",
        width: 150,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (this._isEvent(x.data.benefit.benefitType) ? x.data.benefit.minPeople : "N/A"),
        headerName: "Min People",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        valueGetter: (x) => (this._isEvent(x.data.benefit.benefitType) ? x.data.participants.length : "N/A"),
        headerName: "Participants",
        minWidth: 130,
        suppressSizeToFit: false,
      },
      {
        headerName: "Accept",
        headerClass: "text-center",
        type: ["buttonColumn"],
        pinned: 'right',
        cellRendererParams: {
          buttonClass: "btn btn-success",
          buttonText: "",
          iconClass: "fas fa-check",
          onClick: this._onAccept.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      },
      {
        headerName: "Decline",
        headerClass: "text-center",
        type: ["buttonColumn"],
        pinned: 'right',
        cellRendererParams: {
          buttonClass: "btn btn-danger",
          buttonText: "",
          iconClass: "fas fa-times",

          onClick: this._onDecline.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      }
    ]);

    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No benefit requests entries.</span>';
  }

  ngOnInit(): void {
    this.startLoader();
    this._loadData();
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;
    this.benefitRequest = clone($event.data);
    this.currentSelectionIdx = this.benefitRequest.id;
  }

  onRowDoubleClicked(_: any): void {
    if(!this._isEvent(this.benefitRequest.benefit.benefitType)){
      return;
    } 
    this._openEventRequestModal();
  }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    throw new Error('Method not implemented.');
  }

  private _onAccept(): void {
    this.startLoader();
    this.dialogService
      .showDialog({
        title: `Are you sure you want to accept this benefit request?`,
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#18ce0f",
        confirmButtonText: "Accept",
      })
      .then((result) => {
        if (!result.value) return;
        this.benefitRequestService
          .approveBenefitRequest(this.benefitRequest.id)
          .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
          .subscribe(() => {
            this.data.splice(this.currentSelectionIdx, 1);
            this.updateGridAfterDelete();
            this.benefitRequest = null;
            this.dialogService.showSuccessMessage(
              "Success!",
              "Selected benefit request has been accepted."
            )
          },
            (err) => {
              if (this.benefitRequest.eventDate){
                this.dialogService.showSimpleDialog("Error", "Event not available anymore", "error");
              }
              else{
                this.dialogService.showSimpleDialog("Error", err, "error");
              }
            }
          );
      });
  }

  private _onDecline(): void {
    this.startLoader();
    this.dialogService
      .showDialog({
        title: `Are you sure you want to decline this benefit request?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DC3545",
        confirmButtonText: "Decline",
      })
      .then((result) => {
        if (!result.value) return;
        this.benefitRequestService
          .declineBenefitRequest(this.benefitRequest.id)
          .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
          .subscribe(() => {
            this.data.splice(this.currentSelectionIdx, 1);
            this.updateGridAfterDelete();
            this.benefitRequest = null;
            this.dialogService.showSuccessMessage(
              "Success!",
              "Selected benefit request has been declined."
            );
          },
            (err) => {
              if (this.benefitRequest.eventDate){
                this.dialogService.showSimpleDialog("Error", "Event not available anymore", "error");
              }
              else{
                this.dialogService.showSimpleDialog("Error", err, "error");
              }
            }
          );
      });
  }

  private _loadData(): void {
    this.benefitRequestService.getAddBenefitRequests()
      .pipe(takeUntil(this.ngUnsubscribe), finalize( () => this.stopLoader()))
      .subscribe((benefitRequests: BenefitRequest[]) => {
        this.data = benefitRequests;
      });
  }

  private _openEventRequestModal(): void {
    const initialState = {
      benefitRequestId: this.benefitRequest.id,
      isAdmin: true
    };

    this.eventModalRef = this.bsModalService.show(EventDetailsModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg align-center-modal'
    });
  }

  private _isEvent(benefitType: BenefitTypeDto): boolean {
    return benefitType.name === BenefitTypeEnum.EVENT;
  }
}
