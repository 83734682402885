import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { finalize, takeUntil } from "rxjs/operators";
import { MonthlyStatsModel } from "src/app/models/monthlyStats/MonthlyStatsModel";
import { MonthlySalaryService } from "src/app/services/monthly-salary.service";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";

@Component({
  selector: "trackify-user-monthly-stats",
  templateUrl: "./user-monthly-stats.component.html",
  styleUrls: ["./user-monthly-stats.component.scss"],
})
export class UserMonthlyStatsComponent
  extends GridComponentBase<MonthlyStatsModel>
  implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private monthlySalaryService: MonthlySalaryService
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        field: "year",
        headerName: "Year",
        width: 95,
        suppressSizeToFit: true,
      },
      {
        field: "monthName",
        headerName: "Month",
        width: 95,
        suppressSizeToFit: true,
      },
      {
        field: "targetHours",
        headerName: "Target",
        width: 95,
        suppressSizeToFit: true,
      },
      {
        field: "totalHoursInMonth",
        headerName: "Total hours",
        width: 95,
        suppressSizeToFit: true
      },
      {
        field: "workingDays",
        headerName: "Working days",
        width: 130,
        suppressSizeToFit: true,
      },
      {
        field: "dailyHours",
        headerName: "Hours/Day",
        width: 130,
        suppressSizeToFit: true,
      },
      {
        field: "workedHours",
        headerName: "Worked Hours",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        valueFormatter: (x) =>
          x.data.notPaidHolidayHours > 0
            ? `${x.data.notPaidHolidayHours + x.data.holidayHours} (${x.data.notPaidHolidayHours}h unpaid)`
            : x.data.holidayHours == 0
              ? 0
              : x.data.holidayHours,
        headerName: "Holiday Hours Taken",
        width: 150,
        suppressSizeToFit: true,
      },
      {
        field: "medicalHolidayHours",
        headerName: "Medical Free Hours",
        width: 150,
        suppressSizeToFit: true,
      },
      {
        field: "freeHours",
        headerName: "Free Hours",
        width: 130,
        suppressSizeToFit: true,
      },
      {
        field: "freeDays",
        headerName: "No. Free Days",
        width: 130,
        suppressSizeToFit: true,
      },
      {
        field: "generatedHolidays",
        headerName: "Holiday Hours Resulted",
        width: 140,
        suppressSizeToFit: true,
        valueFormatter: (params) => params.data.generatedHolidays.toFixed(2),
      },
      {
        field: "holidaysRemaining",
        headerName: "Holiday Left",
        width: 130,
        suppressSizeToFit: true,
        valueFormatter: (params) => params.data.holidaysRemaining.toFixed(2),
      },
      {
        field: "salaryMultiplicator",
        headerName: "Salary Multiplicator",
        minWidth: 135,
        suppressSizeToFit: false,
        valueFormatter: (params) =>
          params.data.salaryMultiplicator.toFixed(4),
      },
    ]);
    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No Monthly stats entries.</span>';
  }

  ngOnInit() {
    this._getUserMonthlySalary();
  }

  private _getUserMonthlySalary() {
    this.startLoader();
    this.monthlySalaryService
      .getUserMonthlySalary(this.route.snapshot.params.id)
      .pipe(takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.stopLoader();
        }))
      .subscribe((result) => {
        this.data = result;
      });
  }

  onRowSelected(): void { }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  isModelValid(): boolean {
    return true;
  }
}
