import { Component, OnInit, ViewChild } from "@angular/core";
import { forkJoin } from "rxjs";
import { ModalDirective } from "ngx-bootstrap/modal";
import { finalize, takeUntil } from "rxjs/operators";

import { clone, dateComparator } from "src/app/shared/utils/Utils";
import { formatLocalTime } from "src/app/shared/utils/Formatters";

import { HolidayRequestService } from "src/app/services/holiday-request/holidayRequest.service";
import { DialogService } from "src/app/shared/services/dialog.service";
import { HolidayRequestTypeService } from "src/app/services/holiday-request/holiday-request-type.service";

import { HolidayRequestModel } from "src/app/models/holiday-request/HolidayRequestModel";
import { HolidayRequestUserModel } from "src/app/models/holiday-request/HolidayRequestUserModel";
import { HolidayRequestTypeModel } from "src/app/models/holiday-request/HolidayRequestTypeModel";
import { getUtcDateFromString } from "../../../shared/utils/MomentUtils";
import { AgGridUtils } from "src/app/shared/utils/AgGridUtils";
import { GridComponentBase } from "src/app/shared/components/base/GridComponentBase";
import { StatusConstants } from "src/app/constants/statuses-constants";
import { HolidayType } from "src/app/models/holiday-type/HolidayType";
import { DateTimeModel } from "src/app/models/DateTimeModel";

@Component({
  selector: "trackify-user-holiday-request",
  templateUrl: "./user-holiday-request.component.html",
  styleUrls: ["./user-holiday-request.component.scss"],
})
export class UserHolidayRequestComponent
  extends GridComponentBase<HolidayRequestModel>
  implements OnInit {
  newHolidayRequest: HolidayRequestUserModel = new HolidayRequestUserModel();
  holidayTypes: HolidayRequestTypeModel[];
  specialHolidayTypes: HolidayRequestTypeModel[] = [];

  isEditMode: boolean;
  pending: string = StatusConstants.PENDING_STATUS;

  minDate: string;
  maxDate: string;
  errorMessage: string;

  uploadedDocument: any;
  isSpecialHoliday: boolean = false;
  isMedicalOrSpecialHoliday: boolean = false;

  formatDateForTable = formatLocalTime;
  @ViewChild("staticModal", { static: true }) modal: ModalDirective;

  constructor(
    private holidayRequestService: HolidayRequestService,
    private holidayRequestTypeService: HolidayRequestTypeService,
    private dialogService: DialogService
  ) {
    super();
    AgGridUtils.addDefaultColumnTypes(this.columnTypes);

    this.addColumnDefs([
      {
        valueGetter: (x) => this.formatDateForTable(x.data.startDate),
        headerName: "Start Date",
        width: 250,
        sort: "asc",
        comparator: (_, __, nodeA, nodeB) =>
          dateComparator(nodeA.data.startDate, nodeB.data.startDate),
        suppressSizeToFit: false,
      },
      {
        valueGetter: (x) => this.formatDateForTable(x.data.endDate),
        headerName: "End Date",
        width: 250,
        suppressSizeToFit: false,
      },
      {
        field: "holidayRequestTypeName",
        headerName: "Type",
        width: 150,
        suppressSizeToFit: false,
      },
      {
        field: "statusName",
        headerName: "Status",
        width: 150,
        suppressSizeToFit: false,
      },
      {
        headerName: "Edit",
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-success",
          buttonText: "",
          iconClass: "fa fa-edit",
          shouldCheckStatus: true,
          getStatusToCheck: () => [StatusConstants.ACCEPTED_STATUS],
          onClick: this._onEdit.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      },
      {
        headerName: "Remove",
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-danger",
          buttonText: "",
          iconClass: "fa fa-trash",
          shouldCheckStatus: true,
          getStatusToCheck: () => [StatusConstants.ACCEPTED_STATUS],
          onClick: this._onDelete.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      },
      {
        headerName: " View document",
        headerClass: "text-center",
        type: ["buttonColumn"],
        cellRendererParams: {
          buttonClass: "btn btn-success",
          buttonText: "",
          iconClass: "fa fa-eye",
          shouldCheckStatus: true,
          shouldCheckDocument: true,
          getStatusToCheck: () => [StatusConstants.ACCEPTED_STATUS],
          onClick: this._onViewDocument.bind(this),
        },
        cellRenderer: "buttonCellRenderer",
      },
    ]);
    this.gridOptions.overlayNoRowsTemplate =
      '<span class="ag-overlay-no-rows-center">No holiday requests entries.</span>';
  }
  ngOnInit(): void {
    this.startLoader();
    this._setMaxDate();
    this._loadUserHolidayRequestData();
  }

  deleteRequest(requestId: number): void {
    this.dialogService.confirmationDialog("Yes, delete it").then((resp) => {
      if (resp.isConfirmed) {
        this.holidayRequestService
          .deleteHolidayRequest(requestId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            (_) => {
              this.gridOptions.api.showLoadingOverlay();
              this.data.splice(
                this.data.findIndex((elem) => elem.id == requestId),
                1
              );
              this.updateGridAfterDelete();
              this.gridOptions.api.hideOverlay();
              this.dialogService.showSimpleDialog(
                "Succes!",
                "Request deleted successfully",
                "success"
              );
            },
            (_) => {
              this.dialogService.showErrorMessage(
                "Oops...",
                "Something went wrong!"
              );
            }
          );
      }
    });
  }

  saveRequest(): void {
    if (!this.isModelValid()) {
      return;
    }

    const start = new Date(this.newHolidayRequest.startDate);
    const end = new Date(this.newHolidayRequest.endDate);
    this.newHolidayRequest.startDateComponents = new DateTimeModel(
      start.getDate(),
      start.getMonth() + 1,
      start.getFullYear()
    );
    this.newHolidayRequest.endDateComponents = new DateTimeModel(
      end.getDate(),
      end.getMonth() + 1,
      end.getFullYear()
    );

    this.gridOptions.api.showLoadingOverlay();
    if (!this.isEditMode) {
      this.holidayRequestService
        .addHolidayRequest(this.newHolidayRequest)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => this.gridOptions.api.hideOverlay()))
        .subscribe(
          (newRequest) => {
            if (this.uploadedDocument != undefined) {
              this._processHolidayWithDoc(newRequest);
            } else {
              this.data.push(newRequest);
              this.updateGridAfterAdd(newRequest);
              this.dialogService.showSimpleDialog(
                "Succes!",
                "Request added successfully",
                "success"
              );
              this.closeModal();
            }
          },
          (error) => {
            this.dialogService.showSimpleDialog("Error!", error, "error");
          }
        );
    } else {
      this.holidayRequestService
        .updateHolidayRequest(this.newHolidayRequest)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => this.gridOptions.api.hideOverlay()))
        .subscribe(
          (newRequest) => {
            if (this.uploadedDocument != undefined) {
              this._processHolidayWithDoc(newRequest);
            } else {
              this.data[this.currentSelectionIdx] = newRequest;
              this.updateGridAfterDelete();
              this.updateGridAfterAdd(newRequest);
              this.dialogService.showSimpleDialog(
                "Succes!",
                "Request updated successfully",
                "success"
              );
              this.closeModal();
            }
          },
          (error) => {
            this.dialogService.showSimpleDialog("Error!", error, "error");
          }
        );
    }
  }

  editRequest(selection): void {
    this.isEditMode = true;
    this.newHolidayRequest = clone(
      this.data.find((req) => req.id == selection.id)
    );

    if (
      this._checkForSpecialHoliday(
        this.newHolidayRequest.holidayRequestTypeName
      )
    ) {
      this.newHolidayRequest.specialHolidayRequestTypeId =
        this.newHolidayRequest.holidayRequestTypeId;
      this.newHolidayRequest.holidayRequestTypeId = 0;
      this.isSpecialHoliday = true;
      this.isMedicalOrSpecialHoliday = true;
    }

    if (
      this._checkForMedicalHoliday(
        this.newHolidayRequest.holidayRequestTypeName
      )
    ) {
      this.isMedicalOrSpecialHoliday = true;
    }

    this.modal.show();
    this.uploadedDocument = undefined;
  }

  onAddHoliday(): void {
    this.isEditMode = false;
    this._setMaxDate();

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const currentYear = currentDate.getFullYear();

    const isAfter6thDay = currentDay > 6;

    let minMonth = currentMonth;
    let minYear = currentYear;

    if (!isAfter6thDay && currentMonth === 0) {
      minMonth = 11;
      minYear = currentYear - 1;
    } else if (!isAfter6thDay) {
      minMonth = currentMonth - 1;
    }

    const minDate = new Date(minYear, minMonth, 1);
    this.minDate = minDate.toISOString();
    this.newHolidayRequest = new HolidayRequestUserModel();
    this.modal.show();
    this.uploadedDocument = undefined;
  }

  closeModal(): void {
    this.isSpecialHoliday = false;
    this.isMedicalOrSpecialHoliday = false;
    this.isEditMode = false;
    this.errorMessage = null;
    this.modal.hide();
  }

  updateHolidayRequestDocument(files: any): void {
    this.uploadedDocument = files;
  }

  onRowDoubleClicked(_: any): void { }

  validateModel(_: string): string {
    return "";
  }

  onRowSelected($event: any): void {
    if (!$event.node.isSelected()) return;
    this.currentSelection = clone($event.data);
    this.currentSelectionIdx = this.data.indexOf(
      this.data.find((u) => u.id == this.currentSelection.id)
    );
  }

  isModelValid(): boolean {
    this.errorMessage = "";

    if (!this.newHolidayRequest.startDate || !this.newHolidayRequest.endDate) {
      this.errorMessage = "Missing date!";
      return false;
    }

    const start = this.newHolidayRequest.startDate.slice(0, 10);
    const end = this.newHolidayRequest.endDate.slice(0, 10);
    
    if (end < start) {
      this.errorMessage = "Check dates again!";
      return false;
    }

    if (this.isSpecialHoliday && !this.newHolidayRequest.specialHolidayRequestTypeId) {
      this.errorMessage = "Select holiday type!";
      return false;
    }

    return true;
  }

  updateHolidayType(selection): void {
    const selectedType = selection.options[selection.selectedIndex].text;
    this.isMedicalOrSpecialHoliday =
      selectedType != HolidayType.Paid && selectedType != HolidayType.NotPaid;

    this.isSpecialHoliday = this._checkForSpecialHoliday(selectedType);

    this.newHolidayRequest.specialHolidayRequestTypeId = null;
  }

  private _loadUserHolidayRequestData(): void {
    forkJoin([
      this.holidayRequestService.getCurrentUserHolidaysRequests(),
      this.holidayRequestTypeService.getAll(),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.stopLoader())
      )
      .subscribe(([requests, types]) => {
        this.data = requests;
        this.holidayTypes = types;

        this.holidayTypes.forEach((holiday) => {
          if (
            holiday.name != HolidayType.Paid &&
            holiday.name != HolidayType.NotPaid &&
            holiday.name != HolidayType.Medical
          ) {
            let special: HolidayRequestTypeModel = {
              id: holiday.id,
              name: holiday.name,
              isBillable: holiday.isBillable
            };
            this.specialHolidayTypes.push(special);
            this.holidayTypes = this.holidayTypes.filter(
              (item) => item.id != holiday.id
            );
          }
        });
      });
  }

  private _processHolidayWithDoc(newRequest: HolidayRequestModel): void {
    let fileToUpload = <File>this.uploadedDocument[0];
    const formData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    this.holidayRequestService
      .addDocumentForHolidayRequest(newRequest.id, formData)
      .subscribe((holidayRequestWithFile) => {
        if (this.isEditMode) {
          this.data[this.currentSelectionIdx] = holidayRequestWithFile;
          this.updateGridAfterDelete();
          this.updateGridAfterAdd(holidayRequestWithFile);
          this.dialogService.showSimpleDialog(
            "Succes!",
            "Request updated successfully",
            "success"
          );
        } else {
          this.data.push(holidayRequestWithFile);
          this.updateGridAfterAdd(holidayRequestWithFile);
          this.dialogService.showSimpleDialog(
            "Succes!",
            "Request added successfully",
            "success"
          );
        }
        this.gridOptions.api.hideOverlay();
        this.closeModal();
      });
  }

  private _checkForSpecialHoliday(value: string): boolean {
    return (
      value != HolidayType.Medical &&
      value != HolidayType.Paid &&
      value != HolidayType.NotPaid
    );
  }

  private _setMaxDate(): void {
    this.maxDate = getUtcDateFromString().add(1, "years").toISOString();
  }

  private _checkForMedicalHoliday(value: string): boolean {
    return value == HolidayType.Medical;
  }

  private _onEdit(e): void {
    this.editRequest(e.data);
  }

  private _onDelete(e): void {
    this.deleteRequest(e.data.id);
  }

  private _onViewDocument(e): void {
    if (e.data.filePath) {
      this.openDoc(e.data.filePath);
    } else {
      this.dialogService.showErrorMessage("Oops...", "No files uploaded!");
    }
  }

  private openDoc(documentUrl: string): void {
    window.open(documentUrl);
  }
}
