import { ChangeDetectorRef, Component, HostListener, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PlannerConstants } from 'src/app/constants/planner-contants';
import { SetPlannerProjectModel } from 'src/app/models/planner/SetPlannerProjectModel';

import { MomentConstants } from '../../../../constants/moment-constants';
import { PlannerModel } from '../../../../models/planner/PlannerModel';

@Component({
  selector: 'trackify-context-menu-cell-renderer',
  templateUrl: './context-menu-cell-renderer.component.html',
  styleUrls: ['./context-menu-cell-renderer.component.scss']
})
export class ContextMenuCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  updatedProject: SetPlannerProjectModel = new SetPlannerProjectModel();
  project: any
  plannerData: PlannerModel;

  @ViewChild("projectsModal", { static: true }) modal: ModalDirective;

  constructor(private cdr: ChangeDetectorRef) { }

  @HostListener('document:click')

  clickout() {
    if (this.plannerData) {
      this.plannerData.showContextMenu = false;
    }
  }

  agInit(params: any): void {
    this.params = params;
    if (this.params.data.weeklyPlanners) {
      const plannerData = this.params.data.weeklyPlanners
        .find(wp => moment(wp.date).format(MomentConstants.YEAR_MONTH_DAY) == this.params.currentDate);

      if (plannerData) {
        this.plannerData = plannerData;
        this.project = plannerData.project;
      }
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  refresh(_: any): boolean {
    return false;
  }

  onContextMenu() {
    if (!this.plannerData.editablePlannerModel) {
      return;
    }

    if (!this.plannerData) {
      return false;
    }

    this.plannerData.showContextMenu = !this.plannerData.showContextMenu;
    if (this.plannerData.showContextMenu)
      this.params.onContextMenuStateChanged(this.plannerData.id);
    return false;
  }

  getProject(): string {
    if (!this.project) {
      return PlannerConstants.WEEKEND_VALUE
    }

    if (this.project.custom) {
      this.project.color = null;
      this.project.name = null;
      return this.project.custom;
    }

    else if (this.project.name) {
      if (this.project.color.length < 9) {
        this.project.color += 'AA';
      }
      return this.project.name;
    }
    return '-';
  }

  onOpenModal() {
    this.params.onOpenModal(this.plannerData);
  }

  onCopyPastDayClick() {
    this.params.onCopyPastDayClick(this.plannerData);
  }

  onCopyPastWeekClick() {
    this.params.onCopyPastWeekClick(this.plannerData);
  }

  shouldDisplayContextMenuTop() {
    return this.params.shouldDisplayContextMenuTop(this.params.node);
  }
}
