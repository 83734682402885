import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BenefitStatusTypeEnum } from 'src/app/models/user-benefits/BenefitStatusTypeEnum';
import { clone } from 'src/app/shared/utils/Utils';
import { BenefitBriefByCategoryModel } from 'src/app/models/user-benefits/BenefitBriefByCategoryModel';
import { ParticipantEventStatus } from 'src/app/models/user-benefits/ParticipantEventStatus';
import { BenefitCardModel } from 'src/app/models/user-benefits/BenefitCardModel';
import { BenefitTypeEnum } from 'src/app/models/admin-benefits/BenefitTypeDto';
import { BenefitEnrollmentStatusEnum } from 'src/app/models/user-benefits/BenefitEnrollmentStatusEnum';
import { BenefitFilterValuesEnum } from 'src/app/models/user-benefits/BenefitFilterValuesEnum';
import { EventBenefitCardDetails } from 'src/app/models/user-benefits/EventBenefitCardDetails';
import { BenefitOneTimeCardModel } from 'src/app/models/user-benefits/BenefitOneTimeCardModel';

@Component({
  selector: 'trackify-current-user-configure-benefits',
  templateUrl: './current-user-configure-benefits.component.html',
  styleUrls: ['./current-user-configure-benefits.component.scss']
})
export class CurrentUserConfigureBenefitsComponent implements OnInit {
  @ViewChild('eventFilterSelect', { static: false }) eventFilterSelect!: ElementRef<HTMLSelectElement>;
  @ViewChild('recurrentFilterSelect', { static: false }) recurrentFilterSelect!: ElementRef<HTMLSelectElement>;

  @Input() isInTab: boolean = false;
  @Input() benefits: BenefitBriefByCategoryModel[];

  @Output() eventAdded = new EventEmitter<EventBenefitCardDetails>();
  @Output() eventDeleted = new EventEmitter<number>();
  @Output() eventModified = new EventEmitter<BenefitCardModel>();
  @Output() recurrentBenefitModified = new EventEmitter<BenefitCardModel>();
  @Output() oneTimeBenefitModified = new EventEmitter<BenefitOneTimeCardModel>();

  benefitType: string = null;
  BenefitStatusTypeEnum: BenefitStatusTypeEnum;
  filtredBenefits: BenefitBriefByCategoryModel[];
  BenefitTypeEnum: BenefitTypeEnum;
  BenefitFilterValuesEnum: BenefitFilterValuesEnum;
  isActiveEvent: boolean = false;
  allFilterValue: number = 0;

  ngOnInit(): void {
    this.filtredBenefits = clone(this.benefits);
    this.benefitType = this._checkBenefitType();
    const allowedActiveEventStatuses: ParticipantEventStatus[] = [ParticipantEventStatus.Invited, ParticipantEventStatus.Joined, ParticipantEventStatus.MyEvent]
    this.isActiveEvent = this.benefits.some((category: BenefitBriefByCategoryModel) =>
      category.benefits.some((benefit: BenefitCardModel) =>
        allowedActiveEventStatuses.includes(benefit.enrollmentStatus as ParticipantEventStatus)
      )
    );
  }

  ngOnChanges(): void {
    this._updateBenefitsAfterEventIsAddedOrDeleted();
  }

  onStatusFilterChange(selectedValue: string): void {
    let index = 0;
    this.benefits.forEach((element: BenefitBriefByCategoryModel) => {

      let currentBenefits = clone(element.benefits);

      switch (selectedValue) {
        // currently active benefits
        case BenefitFilterValuesEnum.Enrolled:
          this.filtredBenefits[index].benefits = currentBenefits.filter((benefit: BenefitCardModel) => {
            if (benefit.enrollmentStatus == BenefitEnrollmentStatusEnum.Ongoing)
              return benefit;
          })
          break;
        // requested benefits (waiting for the admin to accept/decline) and 
        // benefits in enrollment/cancellation process
        case BenefitFilterValuesEnum.Pending:
          this.filtredBenefits[index].benefits = currentBenefits.filter(benefit => {
            if ((benefit.enrollmentStatus == BenefitEnrollmentStatusEnum.Invalid && benefit.benefitRequest)
              || benefit.enrollmentStatus == BenefitEnrollmentStatusEnum.EnrollmentPending
              || benefit.enrollmentStatus == BenefitEnrollmentStatusEnum.CancellationPending)
              return benefit;
          })
          break;
        // not requested benefits
        case BenefitFilterValuesEnum.Available:
          this.filtredBenefits[index].benefits = currentBenefits.filter(benefit => {
            if (benefit.enrollmentStatus == BenefitEnrollmentStatusEnum.Invalid && !benefit.benefitRequest)
              return benefit;
          })
          break;
        default:
          this.filtredBenefits[index].benefits = currentBenefits;
          break;
      }
      index++;
    });
  }

  onEventChange(selectedValue: string): void {
    let index = 0;
    this.benefits.forEach((element: BenefitBriefByCategoryModel) => {
      let currentBenefits = clone(element.benefits);
      switch (selectedValue) {
        case BenefitFilterValuesEnum.All:
          this.filtredBenefits[index].benefits = currentBenefits;
          break;
        case BenefitFilterValuesEnum.MyEvents:
          this.filtredBenefits[index].benefits = currentBenefits.filter((benefit: BenefitCardModel) => benefit.enrollmentStatus == ParticipantEventStatus.MyEvent);
          break;
        case BenefitFilterValuesEnum.Invited:
          this.filtredBenefits[index].benefits = currentBenefits.filter((benefit: BenefitCardModel) => benefit.enrollmentStatus == ParticipantEventStatus.Invited);
          break;
        case BenefitFilterValuesEnum.Joined:
          this.filtredBenefits[index].benefits = currentBenefits.filter((benefit: BenefitCardModel) => benefit.enrollmentStatus == ParticipantEventStatus.Joined);
          break;
        default:
          break;
      }
      index++;
    });
  }

  onEventAdded(data: EventBenefitCardDetails): void {
    this.eventAdded.emit(data);
  }

  onEventDeleted(data: number): void {
    this.eventDeleted.emit(data);
  }

  onEventModified(data: BenefitCardModel): void {
    this.eventModified.emit(data);
    this.onEventChange(this.eventFilterSelect.nativeElement.value);
  }

  onRecurrentBenefitModified(data: BenefitCardModel): void {
    this.recurrentBenefitModified.emit(data);
    this.onStatusFilterChange(this.recurrentFilterSelect.nativeElement.value);
  }

  onOneTimeBenefitModified(data: BenefitOneTimeCardModel): void {
    this.oneTimeBenefitModified.emit(data);
  }
  
  private _updateBenefitsAfterEventIsAddedOrDeleted(): void {
    this.filtredBenefits = clone(this.benefits);
    this.onEventChange(BenefitFilterValuesEnum.All);
    if (this.eventFilterSelect) {
      this.eventFilterSelect.nativeElement.selectedIndex = this.allFilterValue;
    }
  }

  private _checkBenefitType(): string {
    if (this.benefits.some(item => item.benefits.length > 0)) {
      for (let i = 0; i < this.benefits.length; i++) {
        if (this.benefits[i].benefits.length > 0) {
          return this.benefits[i].benefits[0].benefitType;
        }
      }
    }
  }
}